import { PaymentStatus } from '@xendit/dd-connector-commons/src/direct-debit-payment/entities';
import { DirectDebitPayment } from '@xendit/sdk-node-direct-debit-service';

export const COMPLETED_PAYMENT_STATUSES = [PaymentStatus.COMPLETED, PaymentStatus.RECORDING_TRANSACTION];

export function isCompletePayment(payment: DirectDebitPayment): boolean {
  return COMPLETED_PAYMENT_STATUSES.includes(payment.status as never);
}
export function isFailedPayment(payment: DirectDebitPayment): boolean {
  return payment.status === PaymentStatus.FAILED;
}

export function isUnknownPaymentStatus(payment: DirectDebitPayment): boolean {
  return payment.status === PaymentStatus.UNKNOWN;
}
