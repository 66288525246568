import React, { useState, useEffect } from 'react';
import { SECOND } from '../utils/time';

interface Props {
  className?: string;
  start: number;
  interval?: number;
  format?: boolean;
  onComplete: () => void;
}
// start and interval should be in seconds
export const CountDown: React.FC<Props> = ({ className = '', start, interval = 1, format = false, onComplete }) => {
  const [value, setValue] = useState(start * SECOND);

  useEffect(() => {
    const toSubtract = interval * SECOND;
    if (value > 0) {
      // TODO: explore setInterval seems to be more correct
      const id = setTimeout(() => {
        setValue(value - toSubtract);
      }, toSubtract);
      return () => clearTimeout(id);
    }
    onComplete();
    return () => {};
  }, [value]);

  const formattedValue = format ? new Date(value).toISOString().substr(14, 5) : value / SECOND;
  return <span className={className}>{formattedValue}</span>;
};
