import '@bit/xenditgroup.ui-toolkit.common';
import '@bit/xenditgroup.ui-toolkit.xendit';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

// only enable on production build aka when `npm run build` output
if (process.env.NODE_ENV === 'production') {
  const { REACT_APP_ENVIRONMENT } = process.env;
  // Datadog RUM
  datadogRum.init({
    applicationId: 'e00a2a81-820c-4c52-ba1c-2676333b6d1c',
    clientToken: 'pub03760885d9d1b79d3fac2fe01ad55f19',
    site: 'datadoghq.com',
    service: 'direct-debit-web',
    env: REACT_APP_ENVIRONMENT,
    // version: REACT_APP_VERSION,
    sampleRate: 5,
    replaySampleRate: 5,
    trackInteractions: true,
    allowedTracingOrigins: [
      /http:\/\/localhost:\d+/, // local
      /https:\/\/.*(\.xendit\.co|\.stg\.tidnex\.dev)/, // integration, staging, prod
    ],
  });
  // Sentry
  Sentry.init({
    dsn: 'https://fae4f0e2f10a4dac872a0e9b5191ca14@o30316.ingest.sentry.io/5825919',
    environment: REACT_APP_ENVIRONMENT, // TODO: configure environemnt properly during build in pipleine
    autoSessionTracking: true,
    // Xendit plan does not support: https://docs.sentry.io/platforms/javascript/guides/react/#monitor-performance
    // integrations: [
    //   new Integrations.BrowserTracing({
    //     routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    //   }),
    // ],
    // release: '', FUTURE TODO: use this next time https://docs.sentry.io/product/releases/setup/
  });
}

datadogRum.startSessionReplayRecording();

ReactDOM.render(
  <React.StrictMode>
    {/* https://docs.sentry.io/platforms/javascript/guides/react/components/errorboundary/ */}
    <Sentry.ErrorBoundary showDialog>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
