import { ClassNames, css } from '@emotion/react';
import React from 'react';
import ReactOtpInput from '../react-otp-input';

interface Props {
  length: number;
  value: string;
  onChange: (v: string) => void;
  errorMsg?: string;
}

export const OtpInput: React.FC<Props> = ({ length, value, onChange, errorMsg }) => (
  <>
    <ClassNames>
      {/* eslint-disable-next-line no-shadow */}
      {({ css }) => (
        <ReactOtpInput
          inputStyle={css`
            text-align: center;
            width: 40px !important;
            height: 54px;
            border: 1px solid var(--light);
            border-radius: 4px;
            font-size: 32px;
            outline: none;
            caret-color: transparent;
            background-color: var(--white);
            padding: 0;
            &:focus {
              border: 2px solid var(--primary);
            }
            &::selection {
              color: var(--primary);
              background: transparent;
            }
          `}
          errorStyle={css`
            border-color: var(--danger);
            border-width: 1.5px;
            &:focus {
              border: 2px solid var(--danger);
            }
            &::selection {
              color: var(--black);
            }
          `}
          value={value}
          numInputs={length}
          onChange={onChange}
          separator={<div style={{ width: '8px' }} />}
          hasErrored={!!errorMsg}
          shouldAutoFocus
          isInputNum
        />
      )}
    </ClassNames>
    {errorMsg ? (
      <p
        css={css`
          margin: 5px 0px;
          color: var(--danger);
        `}
      >
        {errorMsg}
      </p>
    ) : (
      <div
        css={css`
          height: 30px;
        `}
      />
    )}
  </>
);
