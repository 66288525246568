import React, { useEffect } from 'react';
import { FullPageSpinningWheel } from '../../components/FullPageSpinningWheel';

export interface Props {
  location?: {
    search: string;
  };
}

export const DDRedirectDefaultPending: React.FC<Props> = ({ location }) => {
  const queryParams = new URLSearchParams(location?.search);
  const refreshUrl = queryParams.get('refreshUrl');
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (refreshUrl) {
        window.location.replace(refreshUrl);
      }
    }, 15000);

    return () => clearTimeout(timeout);
  });
  return (
    <FullPageSpinningWheel
      title="Your payment is being processed"
      message="This may take up to 3 minutes to complete. Feel free to close the app and check the status again later."
    />
  );
};
