import { css } from '@emotion/react';
import React from 'react';
import Spinner from '@bit/xenditgroup.ui-toolkit.spinner';

export const FullPageSpinner: React.FC = () => {
  return (
    <div
      css={css`
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        margin-top: 40vh;
      `}
    >
      <Spinner size="lg" variant="primary" />
    </div>
  );
};
