import { css } from '@emotion/react';
import React from 'react';
import PaymentFailedIllus from '../../assets/payment-failed.png';
import { FullPageResult } from '../../components/FullPageResult';

const container = css`
  margin: 0 auto;
  max-width: 1300px;
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DDRedirectDefaultFailed: React.FC = () => {
  return (
    <div css={container}>
      <FullPageResult
        illus={{
          alt: 'Payment unsuccessful!',
          src: PaymentFailedIllus,
        }}
        title="Payment unsuccessful!"
        message="Sorry, but we are unable to proceed with your payment. Kindly try again or choose another payment method. qYou can now close this page and go back to the merchant's site or app to retry."
      />
    </div>
  );
};
