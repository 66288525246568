import * as Sentry from '@sentry/react';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { DirectDebitWebApi } from './apis/api';
import { DDCheckout } from './pages/dd-checkout/DDCheckout';
import { DDRedirectDefaultSuccess } from './pages/dd-payment/DDRedirectDefaultSuccess';
import { DDRedirectDefaultFailed } from './pages/dd-payment/DDRedirectDefaultFailed';
import { DDRedirectDefaultPending } from './pages/dd-payment/DDRedirectDefaultPending';
import { NotFound } from './pages/NotFound';

const App: React.FC = () => {
  const ddApi = new DirectDebitWebApi();
  return (
    <Switch>
      <Route path="/direct_debits/:id/checkout" render={() => <DDCheckout api={ddApi} />} />
      <Route path="/direct_debits/success" render={() => <DDRedirectDefaultSuccess />} />
      <Route path="/direct_debits/failed" render={() => <DDRedirectDefaultFailed />} />
      <Route path="/direct_debits/pending" render={(props) => <DDRedirectDefaultPending location={props.location} />} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Sentry.withProfiler(App);
