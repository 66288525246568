import { css } from '@emotion/react';
import React from 'react';
import PaymentSuccessfulIllus from '../../assets/payment-successful.png';
import { FullPageResult } from '../../components/FullPageResult';

const container = css`
  margin: 0 auto;
  max-width: 1300px;
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DDRedirectDefaultSuccess: React.FC = () => {
  return (
    <div css={container}>
      <FullPageResult
        illus={{
          alt: 'Payment successful!',
          src: PaymentSuccessfulIllus,
        }}
        title="Payment successful!"
        message="You can now close this page and go back to the merchant`s site or app"
      />
    </div>
  );
};
