import {
  DirectDebitPaymentErrorErrorCodeEnum as DDPE,
  NotFoundErrorErrorCodeEnum,
} from '@xendit/sdk-node-direct-debit-service';
import { failure, Failure } from '../../hooks/useRemoteData';

// https://docs.google.com/spreadsheets/d/1CrolcZiqZtZ4wD7IJ_U_ICBvoUYEgSiMhq0iPoWsUng/edit#gid=1762491346
export function directDebitPaymentErrortoFailure(ddpe: DDPE | string | undefined): Failure<string> {
  switch (ddpe) {
    case DDPE.APIVALIDATIONERROR:
      return failure('Unknown Error. Please try again.', true);
    case DDPE.INVALIDOTPERROR:
      return failure('Incorrect OTP. Please enter the correct OTP.', true);
    case DDPE.EXPIREDOTPERROR:
      return failure('Expired OTP. Please request for a new OTP.', true);
    case DDPE.MAXOTPATTEMPTSERROR:
      return failure('Payment has failed due to exceeding incorrect OTP attempts.', false);
    case DDPE.INSUFFICIENTBALANCE:
      return failure('Insufficient balance. Please try again.', true);
    case DDPE.ACCOUNTACCESSBLOCKED:
      return failure(
        'Payment has failed because the source bank account may have been temporarily blocked by the bank.',
        false
      );
    case DDPE.MAXAMOUNTLIMITERROR:
      return failure("Payment has failed due to amount exceeding your bank account's daily limit.", false);
    case DDPE.DIRECTDEBITALREADYCOMPLETED:
      return failure('The payment you are trying to access has already succeeded', false);
    case DDPE.DIRECTDEBITALREADYFAILED:
      return failure('The payment you are trying to access has already failed', false);
    case DDPE.INVALIDPAYMENTMETHODERROR:
      return failure(
        'Payment has failed because the payment method associated with the account has become invalid.',
        false
      );
    case DDPE.CHANNELUNAVAILABLE:
      return failure('Payment has failed because the bank has become unreachable.', false);
    case DDPE.SERVERERROR:
      return failure('Payment has failed due to an unexpected error from the bank. ', true);
    default:
      return failure('Payment has failed due to an unexpected error ', false);
  }
}
export function notFoundErrorToFailure(err: NotFoundErrorErrorCodeEnum): Failure<string> {
  switch (err) {
    case NotFoundErrorErrorCodeEnum.DATANOTFOUNDERROR:
      return failure('The transaction does not exist.', false);
  }
}

export const GENERAL_ERROR_MSG = 'Failed loading the transaction. Please attempt the payment again.';
export const GENERAL_ERROR_MSG_SHORT = 'Failed loading the transaction.';
export const COMPLETE_ERROR_MSG = 'Failed to complete the transaction.';
export const PAYMENT_FAILED_TITLE = 'Payment Failed';
