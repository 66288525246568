import Button from '@bit/xenditgroup.ui-toolkit.button';
import { css } from '@emotion/react';
import React from 'react';
import { breakpoints, mq } from '../theme';

export interface Action {
  label: string;
  onClick: () => void;
}

export interface Props {
  illus: {
    src: string;
    alt: string;
  };
  title?: string;
  message?: string;
  action?: Action;
  note?: string;
}

const container = css`
  display: flex;
  padding: 30px 20px;
  flex-direction: column;
  align-items: center;
  max-width: ${breakpoints.mobile}px;
`;

export const FullPageResult: React.FC<Props> = ({ illus, title, message, action, note }) => {
  return (
    <div css={container}>
      <img
        css={css`
          margin-bottom: 10px;
          max-width: 90vw;
          ${mq.tablet} {
            max-width: 600px;
          }
        `}
        src={illus.src}
        alt={illus.alt}
      />
      {title ? <h6>{title}</h6> : null}
      {message ? (
        <p
          css={css`
            text-align: center;
            margin-bottom: 15px;
          `}
        >
          {message}
        </p>
      ) : (
        <div
          css={css`
            height: 20px;
          `}
        />
      )}
      {action && (
        <Button variant="primary" onClick={() => action.onClick()} block>
          {action.label}
        </Button>
      )}
      {note && (
        <p
          css={css`
            margin-top: 10px;
            color: var(--light);
            font-size: 12px;
            text-align: center;
          `}
        >
          {note}
        </p>
      )}
    </div>
  );
};
