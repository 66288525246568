/* eslint-disable no-shadow */
import Button from '@bit/xenditgroup.ui-toolkit.button';
import { css } from '@emotion/react';
import React from 'react';
import { DirectDebitWebApi } from '../../apis/api';
import MobileVerif from '../../assets/mobile-verification.png';
import { CountDown } from '../../components/Countdown';
import { OtpInput } from '../../components/OtpInput';
import { failure, isSuccess, success, useRemoteData } from '../../hooks/useRemoteData';
import { mq } from '../../theme';

type InputType = 'otp' | 'mpin';

interface Props {
  api: DirectDebitWebApi;
  paymentId: string;
  mobileNumber?: string;
  input: {
    value: string;
    onChange: (v: string) => void;
    length?: number;
    type?: InputType;
  };
  error?: string;
  submitting: boolean;
  reset: () => void;
  onSubmit: () => void;
}

const resendTxt = css`
  font-weight: 900;
  color: var(--blue);
`;

export const OTPInputPage: React.FC<Props> = ({
  paymentId,
  error,
  onSubmit,
  input,
  mobileNumber,
  reset,
  api,
  submitting,
}) => {
  const { length = 6, type = 'otp', onChange, value } = input;
  const resendOTP = useRemoteData(async () => {
    const result = await api.resendOTP(paymentId);
    switch (result._tag) {
      case 'Left':
        return failure(null);
      case 'Right':
        return success(null);
    }
  });
  const onResend = () => {
    if (submitting) {
      // if validate OTP is still in progress (submitting), do nothing
      return;
    }

    if (!isSuccess(resendOTP.result)) {
      resendOTP.run();
    }
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 25px;
        text-align: center;
        ${mq.tablet} {
          max-width: 525px;
          border-radius: 4px;
          border: 0.5px solid var(--lighter);
          padding: 50px 30px;
        }
      `}
    >
      <img
        src={MobileVerif}
        alt="Mobile Verification"
        css={css`
          width: 78px;
          margin-bottom: 10px;
        `}
      />
      <h5
        css={css`
          margin-bottom: 10px;
        `}
      >
        {type === 'otp' ? 'Enter OTP' : 'Enter MPIN'}
      </h5>
      <p
        css={css`
          margin-bottom: 20px;
        `}
      >
        {getInfo(
          type,
          length,
          mobileNumber // replace later
        )}
      </p>
      <OtpInput
        length={length}
        value={value}
        onChange={(v) => {
          if (error) {
            reset();
          }
          onChange(v);
        }}
        errorMsg={error}
      />
      {type === 'otp' ? (
        <div
          role="button"
          css={css`
            margin: 10px 0px 60px 0px;
            cursor: ${resendOTP.isSuccess || submitting ? 'not-allowed' : 'pointer'};
            span {
              opacity: ${resendOTP.isSuccess || submitting ? 0.6 : 1};
            }
          `}
          onKeyPress={onResend}
          onClick={onResend}
          tabIndex={0}
        >
          <span>Didn&apos;t receive it? </span>
          <span
            role="button"
            css={css`
              color: ${resendOTP.isFailure ? 'var(--danger)' : 'var(--blue)'};
              font-weight: 900;
            `}
          >
            {((): string => {
              switch (resendOTP.result.type) {
                case 'LOADING':
                  return 'Resending...';
                case 'NOT_ASKED':
                case 'SUCCESS':
                  return 'Resend Code';
                case 'FAILURE':
                  return 'Resending Failed. Try Again?';
                default:
                  return '';
              }
            })()}
          </span>
          {isSuccess(resendOTP.result) && (
            <>
              <span css={resendTxt}> in </span>
              <CountDown start={60} css={resendTxt} format onComplete={() => resendOTP.reset()} />
            </>
          )}
        </div>
      ) : null}

      <Button
        isLoading={submitting}
        isDisabled={length !== value.length || !!error}
        block
        variant="primary"
        size="md"
        type="submit"
      >
        Submit
      </Button>
    </form>
  );
};

// based: https://docs.google.com/spreadsheets/d/1CrolcZiqZtZ4wD7IJ_U_ICBvoUYEgSiMhq0iPoWsUng/edit#gid=1762491346
function getInfo(it: InputType, length: number, mobileNumber?: string): string {
  switch (it) {
    case 'mpin':
      return `Enter the ${length}-digit MPIN for your account to continue with your payment.`;
    case 'otp':
    default:
      return `Enter the ${length}-digit OTP we sent to your number ${mobileNumber} to continue with your payment.`;
  }
}
