export const breakpoints = {
  mobile: 577,
  tablet: 769,
  desktop: 992,
  lgDesktop: 1200,
};

// FUTURE TODO: make this more accurate
export const mq = (Object.keys(breakpoints) as Array<keyof typeof breakpoints>).reduce((acc, key) => {
  return {
    ...acc,
    [key]: `@media (min-width: ${breakpoints[key]}px)`,
  };
}, {}) as Record<keyof typeof breakpoints, string>;
