import React from 'react';
import { css } from '@emotion/react';

export interface Props {
  title?: string;
  message?: string;
}

export const FullPageSpinningWheel: React.FC<Props> = ({ title, message }) => {
  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
      `}
    >
      <style>
        {`
            .loader {
                font-size: 10px;
                width: 1em;
                height: 1em;
                border-radius: 50%;
                position: relative;
                text-indent: -9999em;
                animation: mulShdSpin 1.1s infinite ease;
                transform: translateZ(0);
              }
              @keyframes mulShdSpin {
                0%,
                100% {
                  box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(17,174,186, 0.2), 2.5em 0em 0 0em rgba(17,174,186, 0.2), 1.75em 1.75em 0 0em rgba(17,174,186, 0.2), 0em 2.5em 0 0em rgba(17,174,186, 0.2), -1.8em 1.8em 0 0em rgba(17,174,186, 0.2), -2.6em 0em 0 0em rgba(17,174,186, 0.5), -1.8em -1.8em 0 0em rgba(17,174,186, 0.7);
                }
                12.5% {
                  box-shadow: 0em -2.6em 0em 0em rgba(17,174,186, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(17,174,186, 0.2), 1.75em 1.75em 0 0em rgba(17,174,186, 0.2), 0em 2.5em 0 0em rgba(17,174,186, 0.2), -1.8em 1.8em 0 0em rgba(17,174,186, 0.2), -2.6em 0em 0 0em rgba(17,174,186, 0.2), -1.8em -1.8em 0 0em rgba(17,174,186, 0.5);
                }
                25% {
                  box-shadow: 0em -2.6em 0em 0em rgba(17,174,186, 0.5), 1.8em -1.8em 0 0em rgba(17,174,186, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(17,174,186, 0.2), 0em 2.5em 0 0em rgba(17,174,186, 0.2), -1.8em 1.8em 0 0em rgba(17,174,186, 0.2), -2.6em 0em 0 0em rgba(17,174,186, 0.2), -1.8em -1.8em 0 0em rgba(17,174,186, 0.2);
                }
                37.5% {
                  box-shadow: 0em -2.6em 0em 0em rgba(17,174,186, 0.2), 1.8em -1.8em 0 0em rgba(17,174,186, 0.5), 2.5em 0em 0 0em rgba(17,174,186, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(17,174,186, 0.2), -1.8em 1.8em 0 0em rgba(17,174,186, 0.2), -2.6em 0em 0 0em rgba(17,174,186, 0.2), -1.8em -1.8em 0 0em rgba(17,174,186, 0.2);
                }
                50% {
                  box-shadow: 0em -2.6em 0em 0em rgba(17,174,186, 0.2), 1.8em -1.8em 0 0em rgba(17,174,186, 0.2), 2.5em 0em 0 0em rgba(17,174,186, 0.5), 1.75em 1.75em 0 0em rgba(17,174,186, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(17,174,186, 0.2), -2.6em 0em 0 0em rgba(17,174,186, 0.2), -1.8em -1.8em 0 0em rgba(17,174,186, 0.2);
                }
                62.5% {
                  box-shadow: 0em -2.6em 0em 0em rgba(17,174,186, 0.2), 1.8em -1.8em 0 0em rgba(17,174,186, 0.2), 2.5em 0em 0 0em rgba(17,174,186, 0.2), 1.75em 1.75em 0 0em rgba(17,174,186, 0.5), 0em 2.5em 0 0em rgba(17,174,186, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(17,174,186, 0.2), -1.8em -1.8em 0 0em rgba(17,174,186, 0.2);
                }
                75% {
                  box-shadow: 0em -2.6em 0em 0em rgba(17,174,186, 0.2), 1.8em -1.8em 0 0em rgba(17,174,186, 0.2), 2.5em 0em 0 0em rgba(17,174,186, 0.2), 1.75em 1.75em 0 0em rgba(17,174,186, 0.2), 0em 2.5em 0 0em rgba(17,174,186, 0.5), -1.8em 1.8em 0 0em rgba(17,174,186, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(17,174,186, 0.2);
                }
                87.5% {
                  box-shadow: 0em -2.6em 0em 0em rgba(17,174,186, 0.2), 1.8em -1.8em 0 0em rgba(17,174,186, 0.2), 2.5em 0em 0 0em rgba(17,174,186, 0.2), 1.75em 1.75em 0 0em rgba(17,174,186, 0.2), 0em 2.5em 0 0em rgba(17,174,186, 0.2), -1.8em 1.8em 0 0em rgba(17,174,186, 0.5), -2.6em 0em 0 0em rgba(17,174,186, 0.7), -1.8em -1.8em 0 0em #ffffff;
                }
              }
        `}
      </style>
      <div
        css={css`
          width: 100%;
          min-height: 10vh;
          display: flex;
          justify-content: center;
          margin-top: 40vh;
        `}
      >
        <span className="loader" />
      </div>
      {title ? <h6>{title}</h6> : null}
      {message ? (
        <p
          css={css`
            text-align: center;
            margin: 0px 10px 15px 10px;
          `}
        >
          {message}
        </p>
      ) : (
        <div
          css={css`
            height: 20px;
          `}
        />
      )}
    </div>
  );
};
