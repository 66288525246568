export function delay(ms: number): Promise<void> {
  return new Promise((res) => setTimeout(res, ms));
}

export function redirectAfter(url?: string, rdelay: number | undefined = 0): () => void {
  const timeoutId = setTimeout(() => {
    if (url) {
      window.location.replace(url);
    }
  }, rdelay);
  return () => clearTimeout(timeoutId);
}
