import { css } from '@emotion/react';
import { promisePoll } from '@xendit/dd-connector-commons/src/commons/promise-poll';
import { fold, left, right } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';
import { DirectDebitWebApi } from '../../apis/api';
import { FullPageSpinner } from '../../components/FullPageSpinner';
import {
  failure,
  isFailure,
  isLoading,
  isNotAsked,
  isSuccess,
  success,
  useRemoteData,
} from '../../hooks/useRemoteData';
import { delay, redirectAfter } from '../../utils/etc';
import { isCompletePayment, isFailedPayment, isUnknownPaymentStatus } from '../../utils/payment';
import { MINUTE, SECOND } from '../../utils/time';
import { OTPInputPage } from './OTPInputPage';
import { FailureRedirectPage, PollingPaymentPage, SuccessRedirectPage } from './RedirectPages';
import {
  COMPLETE_ERROR_MSG,
  GENERAL_ERROR_MSG,
  GENERAL_ERROR_MSG_SHORT,
  PAYMENT_FAILED_TITLE,
  directDebitPaymentErrortoFailure,
  notFoundErrorToFailure,
} from './utils';

const container = css`
  margin: 0 auto;
  max-width: 1300px;
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface Props {
  api: DirectDebitWebApi;
}

export const DDCheckout: React.FC<Props> = ({ api }) => {
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const qs = queryString.parse(search);
  const failureRedirectUrl = typeof qs.failure_redirect_url === 'string' ? qs.failure_redirect_url : undefined;
  const redirectDelay =
    typeof qs.payment_redirect_delay === 'string' ? parseInt(qs.payment_redirect_delay, 10) * SECOND : undefined;

  //
  // Managed Async Calls RemoteData "Services"
  //
  const confirmPayment = useRemoteData(async () => {
    const r = await api.confirmPayment(id);
    switch (r._tag) {
      case 'Left':
        return failure(GENERAL_ERROR_MSG_SHORT, false);
      case 'Right':
        return success(r.right);
    }
  });

  const validateOtp = useRemoteData(async (otp: string) => {
    const result = await api.validateOTP(id, otp);
    switch (result._tag) {
      case 'Left':
        switch (result.left.type) {
          case 'DirectDebitError':
            // TODO: track errors thru DD
            return directDebitPaymentErrortoFailure(result.left.error);
          case 'NotFound':
            return notFoundErrorToFailure(result.left.error);
          case 'Unknown':
            return failure(GENERAL_ERROR_MSG, false);
        }
      case 'Right':
        return success(result.right);
    }
  });
  const getCompletePayment = useRemoteData(async () => {
    try {
      await delay(3000); // wait for 3 secs for first try
      const polledResult = await promisePoll(
        async () => {
          const result = await api.getPayment(id);
          switch (result._tag) {
            case 'Right':
              if (isCompletePayment(result.right)) {
                return right(result.right.success_redirect_url);
              }
              if (isFailedPayment(result.right)) {
                return left({
                  url: result.right.failure_redirect_url,
                  msg: directDebitPaymentErrortoFailure(result.right.failure_code).error,
                });
              }
              throw new Error();
            case 'Left':
              throw new Error();
          }
        },
        {
          retries: 100,
          pollTimeout: SECOND * MINUTE * 5,
          timeBeforeNextRetry: SECOND * 2,
          retryFactor: 2,
        }
      );
      return success(polledResult);
    } catch (err) {
      return failure(null);
    }
  });
  const [otp, setOtp] = useState('');

  //
  // Side Effects
  //

  // Initial Mount
  useEffect(() => {
    (async () => {
      await confirmPayment.run();
    })();
  }, []);
  useEffect(() => {
    // ValidateOTP -> Complete Payment
    if (isSuccess(validateOtp.result) && isCompletePayment(validateOtp.result.data)) {
      const payment = validateOtp.result.data;
      return redirectAfter(payment.success_redirect_url, redirectDelay);
    }
    // ValidateOTP -> Unknown Payment = Poll getCompletePayment
    if (
      isSuccess(validateOtp.result) &&
      isUnknownPaymentStatus(validateOtp.result.data) &&
      isNotAsked(getCompletePayment.result)
    ) {
      getCompletePayment.run();
    }
    // ValidateOTP -> Failed Payment -> Redirect to Failure
    if (isFailure(validateOtp.result) && !validateOtp.result.retryable) {
      return redirectAfter(failureRedirectUrl, redirectDelay);
    }
    // ValidateOTP success -> After poll = redirect to success/failure redirect url
    if (isSuccess(validateOtp.result) && isSuccess(getCompletePayment.result)) {
      return redirectAfter(
        pipe(
          getCompletePayment.result.data,
          fold(
            ({ url }) => url,
            (url) => url
          )
        ),
        redirectDelay
      );
    }
    // After poll error = redirect to failure
    if (isFailure(getCompletePayment.result)) {
      return redirectAfter(failureRedirectUrl, redirectDelay);
    }
  }, [validateOtp.result, getCompletePayment.result]);

  // FUTURE TODO: refactor to easier switch case
  return (
    <div css={container}>
      <Helmet title="Validate Payment" />
      {((): React.ReactNode => {
        switch (confirmPayment.result.type) {
          case 'LOADING':
            return <FullPageSpinner />;
          case 'FAILURE':
            return <FailureRedirectPage msg={confirmPayment.result.error} />;
          case 'SUCCESS':
            switch (validateOtp.result.type) {
              case 'SUCCESS': {
                if (isCompletePayment(validateOtp.result.data)) {
                  if (confirmPayment.result.data.show_confirmation_page) {
                    return (
                      <SuccessRedirectPage
                        countdown={redirectDelay}
                        redirectUrl={validateOtp.result.data.success_redirect_url}
                      />
                    );
                  }
                  redirectAfter(validateOtp.result.data.success_redirect_url, 0);
                  return;
                }
                switch (getCompletePayment.result.type) {
                  case 'LOADING':
                    return <PollingPaymentPage countdown={redirectDelay} />;
                  case 'SUCCESS': {
                    const result = getCompletePayment.result.data;
                    switch (result._tag) {
                      case 'Left':
                        return (
                          <FailureRedirectPage
                            countdown={redirectDelay}
                            redirectUrl={result.left.url}
                            title={PAYMENT_FAILED_TITLE}
                            msg={result.left.msg}
                          />
                        );
                      case 'Right':
                        return <SuccessRedirectPage countdown={redirectDelay} redirectUrl={result.right} />;
                    }
                  }
                  case 'FAILURE':
                    return (
                      <FailureRedirectPage
                        countdown={redirectDelay}
                        redirectUrl={failureRedirectUrl}
                        title={PAYMENT_FAILED_TITLE}
                        msg={COMPLETE_ERROR_MSG}
                      />
                    );
                  case 'NOT_ASKED':
                    return <PollingPaymentPage countdown={redirectDelay} />;
                  default:
                    return null;
                }
              }
              default: {
                if (isFailure(validateOtp.result) && !validateOtp.result.retryable) {
                  return (
                    <FailureRedirectPage
                      countdown={redirectDelay}
                      redirectUrl={failureRedirectUrl}
                      msg={validateOtp.result.error}
                      title={PAYMENT_FAILED_TITLE}
                    />
                  );
                }
                const { input, mobile_number: mobileNum } = confirmPayment.result.data;
                return (
                  <OTPInputPage
                    paymentId={id}
                    api={api}
                    mobileNumber={mobileNum}
                    input={{
                      value: otp,
                      onChange: setOtp,
                      ...input,
                    }}
                    submitting={isLoading(validateOtp.result)}
                    error={isFailure(validateOtp.result) ? validateOtp.result.error : undefined}
                    reset={() => validateOtp.reset()}
                    onSubmit={() => {
                      validateOtp.run(otp);
                    }}
                  />
                );
              }
            }

          default:
            return null;
        }
      })()}
    </div>
  );
};
