import { useState } from 'react';

export interface Success<D> {
  type: 'SUCCESS';
  data: D;
}
export interface NotAsked {
  type: 'NOT_ASKED';
}
export interface Loading {
  type: 'LOADING';
}
export interface Failure<E> {
  type: 'FAILURE';
  retryable: boolean;
  error: E;
}
export type RemoteData<E, D> = NotAsked | Loading | Success<D> | Failure<E>;

export interface UseRemoteDataResult<E, O, I extends unknown[]> {
  run: (...i: I) => Promise<void>;
  isNotAsked: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isFailure: boolean;
  result: RemoteData<E, O>;
  reset: () => void;
}

export function useRemoteData<E, O, I extends unknown[] = unknown[]>(
  fetcher: (...args: I) => Promise<RemoteData<E, O>>
): UseRemoteDataResult<E, O, I> {
  const [result, setResult] = useState<RemoteData<E, O>>({ type: 'NOT_ASKED' });
  return {
    run: async (...args2: I) => {
      if (result.type === 'LOADING') {
        return;
      }
      setResult({ type: 'LOADING' });
      const out = await fetcher(...args2);
      setResult(out);
    },
    result,
    isNotAsked: result.type === 'NOT_ASKED',
    isLoading: result.type === 'LOADING',
    isSuccess: result.type === 'SUCCESS',
    isFailure: result.type === 'FAILURE',
    reset: () => setResult({ type: 'NOT_ASKED' }),
  };
}

export function isSuccess<E, O>(result: RemoteData<E, O>): result is Success<O> {
  return result.type === 'SUCCESS';
}
export function isNotAsked<E, O>(result: RemoteData<E, O>): result is NotAsked {
  return result.type === 'NOT_ASKED';
}
export function isFailure<E, O>(result: RemoteData<E, O>): result is Failure<E> {
  return result.type === 'FAILURE';
}
export function isLoading<E, O>(result: RemoteData<E, O>): result is Loading {
  return result.type === 'LOADING';
}

export function failure<E>(error: E, retryable: boolean | undefined = true): Failure<E> {
  return {
    type: 'FAILURE',
    error,
    retryable,
  };
}
export function success<S = undefined>(data: S): Success<S> {
  return {
    type: 'SUCCESS',
    data,
  };
}
export function loading(): Loading {
  return {
    type: 'LOADING',
  };
}
export function notAsked(): NotAsked {
  return {
    type: 'NOT_ASKED',
  };
}
