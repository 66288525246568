import React from 'react';
import ConfirmedIllus from '../../assets/confirmed-rafiki-1.png';
import FailedIllus from '../../assets/feeling-blue-rafiki-1.png';
import LoadingIllus from '../../assets/loading.png';
import { FullPageResult } from '../../components/FullPageResult';
import { SECOND } from '../../utils/time';

export interface Props {
  countdown?: number;
  redirectUrl?: string;
}

const redirectAction = (url?: string) =>
  url
    ? {
        label: 'Back to Merchant',
        onClick: () => {
          window.location.replace(url);
        },
      }
    : undefined;
const redirectNote = (ms?: number) =>
  ms ? `You will be redirected back to the merchant site ${ms ? `after ${ms / SECOND}s` : `soon`}` : undefined;

export const FailureRedirectPage: React.FC<Props & { msg: string; title?: string }> = ({
  countdown,
  redirectUrl,
  msg,
  title,
}) => {
  return (
    <FullPageResult
      illus={{
        alt: 'Failed Illustration',
        src: FailedIllus,
      }}
      title={title}
      message={msg}
      note={redirectUrl ? redirectNote(countdown) : 'Please close the page to return to the merchant site.'}
      action={redirectAction(redirectUrl)}
    />
  );
};

export const SuccessRedirectPage: React.FC<Props> = ({ countdown, redirectUrl }) => {
  return (
    <FullPageResult
      illus={{
        alt: 'Success Illustration',
        src: ConfirmedIllus,
      }}
      title="Payment Successful!"
      note={redirectUrl ? redirectNote(countdown) : 'Please close the page to return to the merchant site.'}
      action={redirectAction(redirectUrl)}
    />
  );
};

export const PollingPaymentPage: React.FC<Omit<Props, 'redirectUrl'>> = ({ countdown }) => {
  return (
    <FullPageResult
      illus={{
        alt: 'Loading Illustration',
        src: LoadingIllus,
      }}
      title="Processing your transaction"
      message="Please do not refresh the page or click “back” or “close” button"
      note={redirectNote(countdown)}
    />
  );
};
