import {
  DirectDebitApi,
  DirectDebitPayment,
  DirectDebitPaymentError,
  NotFoundError,
} from '@xendit/sdk-node-direct-debit-service';
import axios, { AxiosError } from 'axios';
import { Either, left, right } from 'fp-ts/Either';
import { ConfirmPaymentResponse, ResendOTPResponse, ValidateOTPResponse } from './types';

export class DirectDebitWebApi {
  private directDebitApi: DirectDebitApi;

  constructor() {
    // TODO: is this not dangerous to access directly
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.directDebitApi = new DirectDebitApi({}, '/api', axios.create() as any); // WORKAROUND for now axios versioning
  }

  async confirmPayment(id: string): Promise<ConfirmPaymentResponse> {
    try {
      // API key assignment is done in the reverse proxy
      return right((await this.directDebitApi.confirmDirectDebitPayment('', id)).data);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status) {
        switch (error.response.status) {
          case 404:
            return left({ type: 'NotFound', error: (error.response.data.error_code as NotFoundError).error_code });
          case 400:
            return left({ type: 'Validation' });
        }
      }
      return left({ type: 'Unknown', error });
    }
  }

  async validateOTP(id: string, otp: string): Promise<ValidateOTPResponse> {
    try {
      const { data } = await this.directDebitApi.validateOTPDirectDebitPayment(
        '',
        id,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          otp_code: otp,
        }
      );
      return right(data);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status) {
        const { status, data } = error.response;
        switch (status) {
          case 400:
          case 500:
          case 503:
            return left({
              type: 'DirectDebitError',
              // FUTURE TODO: this is hacky no typechecking add runtime type check later on, sadly openapi does not tie in the errors
              error: (data as DirectDebitPaymentError).error_code,
            });
          case 404:
            return left({
              type: 'NotFound',
              error: (data as NotFoundError).error_code,
            });
          default:
            return left({
              type: 'Unknown',
              error,
            });
        }
      }
      return left({ type: 'Unknown', error });
    }
  }

  async resendOTP(id: string): Promise<ResendOTPResponse> {
    try {
      return right((await this.directDebitApi.resendOTPDirectDebitPayment('', id)).data);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status) {
        const { status, data } = error.response;
        switch (status) {
          case 400:
          case 500:
          case 503:
            return left({
              type: 'DirectDebitError',
              // FUTURE TODO: this is hacky no typechecking add runtime type check later on, sadly openapi does not tie in the errors
              error: (data as DirectDebitPaymentError).error_code,
            });
          case 404:
            return left({
              type: 'NotFound',
              error: (data as NotFoundError).error_code,
            });
          default:
            return left({
              type: 'Unknown',
              error,
            });
        }
      }
      return left({ type: 'Unknown', error });
    }
  }

  async getPayment(id: string): Promise<Either<{ status?: number; error: AxiosError | unknown }, DirectDebitPayment>> {
    try {
      return right((await this.directDebitApi.getDirectDebitPayment('', id)).data);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response?.status) {
        return left({
          status: error.response.status,
          error,
        });
      }
      return left({ error });
    }
  }
}
