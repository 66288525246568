import { css } from '@emotion/react';
import queryString from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';
import NotFoundIllus from '../assets/404.png';
import { Action, FullPageResult } from '../components/FullPageResult';

const container = css`
  margin: 0 auto;
  max-width: 1300px;
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const redirectAction = (url?: string): Action | undefined =>
  url
    ? {
        label: 'Back to Merchant',
        onClick: () => {
          window.location.replace(url);
        },
      }
    : undefined;

export const NotFound: React.FC = () => {
  const { search } = useLocation();
  const qs = queryString.parse(search);
  const failureRedirectUrl = typeof qs.failure_redirect_url === 'string' ? qs.failure_redirect_url : undefined;
  return (
    <div css={container}>
      <FullPageResult
        illus={{
          alt: 'Not Found',
          src: NotFoundIllus,
        }}
        title="Uh-oh! Page not found"
        action={redirectAction(failureRedirectUrl)}
      />
    </div>
  );
};
